import React from 'react';
import GaugeTableEmbedded from '../components/GaugeTableEmbedded'
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../adapters/AppInsights';

class Embedded extends React.Component {

    render() {
        return (

            <div class="container-fluid">
                <GaugeTableEmbedded />
            </div>
        );
    }
}
export default withAITracking(reactPlugin, Embedded);
