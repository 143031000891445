import React from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../adapters/AppInsights';
import { getGaugeReadings, getGaugeList } from '../adapters/DataHelper'

class GaugeHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            retrievedList: false,
            gaugeList: null,
            selectedGauge: null,
            retrievedData: false,
            gaugeData: null
        };
        //this.retrieveGaugeHistory();
        this.retrieveGaugeList();
    }

    async retrieveGaugeList() {
        this.setState({ submitted: true, retrievedList: false, gaugeList: null, selectedGauge: null, retrievedData: false, gaugeData: null });

        let response = await getGaugeList();
        this.setState({ submitted: false, retrievedList: true, gaugeList: response, selectedGauge: null, retrievedData: false, gaugeData: null })
    }

    selectedChanged = async (event) => {
        this.setState({ selectedGauge: event.target.value, retrievedData: false, gaugeData: null });

        var gaugeId = event.target.value;
        var currentDate = new Date();
        var endDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + (currentDate.getDate());
        var pastDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
        var startDate = pastDate.getFullYear() + '-' + (pastDate.getMonth() + 1) + '-' + pastDate.getDate();

        let response = await getGaugeReadings(gaugeId, startDate, endDate);
        this.setState({ retrievedData: true, gaugeData: response });

    }
    
    renderGaugeList(gaugeList) {
        var gaugeItems = [];
        gaugeItems.push(
            <option value="">--- Select One ---</option>
        )
       for (var i = 0; i < gaugeList.length; i++) {
            gaugeItems.push(
                <option value={gaugeList[i].rowKey}>{gaugeList[i].name}</option>
            )
        }
        return (<select onChange={this.selectedChanged} value={this.state.selectedGauge}>{gaugeItems}</select>);
    }

    renderGaugeHistory(gaugeData) {
        var tableRows = [];
        for (var i = 0; i < gaugeData.length; i++) {
            var observedDateTime = new Date(gaugeData[i].gaugeDate);
            var displayTime = observedDateTime.toDateString();


            tableRows.push(
                <tr>
                    <td class="fw-bold">{displayTime}</td>
                    <td class="gaugeData">{Math.round(gaugeData[i].stageAvg * 100) / 100}</td>
                    <td class="gaugeData">{Math.round(gaugeData[i].stageMax * 100) / 100}</td>
                    <td class="gaugeData">{Math.round(gaugeData[i].stageMin * 100) / 100}</td>
                </tr>
            )
        }
        return (<tbody>{tableRows}</tbody>);
    }

    render() {
        let gaugeHistory = !this.state.retrievedData
            ? <tbody><tr><td colspan="3">Select a gauge from the list above.</td></tr></tbody>
            : this.renderGaugeHistory(this.state.gaugeData.gaugeReadings)
        let gaugeListDisplay = !this.state.retrievedList
            ? <span>LOADING</span>
            : this.renderGaugeList(this.state.gaugeList)

        return (
            <div class="container-fluid">
                <Header />
                <div class="container col-12">
                    <h3>Mississippi River Gauge Readings</h3>
                    <dl class="row">
                        <dt class="col-sm-1">Gauge:</dt>
                        <dd class="col-sm-11">{gaugeListDisplay}</dd>
                    </dl>
                    <table class="table table-sm">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Observed Date</th>
                                <th>Average Stage</th>
                                <th>Maximum</th>
                                <th>Minimum</th>
                            </tr>
                        </thead>
                        {gaugeHistory}
                    </table>
                </div>
                <Footer />
            </div>
        );
    }
}
export default withAITracking(reactPlugin, GaugeHistory);
