import React from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'

class About extends React.Component {
    render() {
        return (
            <div class="container-fluid">
                <Header />
                <h2>Support Center</h2>
                <h4>Contact Information</h4>
                <p>
                If you need assistance, please don't hesitate to contact our support team. We are here to help!
                </p>
                <ul>
                <li>Email: <a href="mailto:gaugestage@timbob.com">gaugestage@timbob.com</a></li>
                </ul>

                <Footer />
            </div>
        );
    }
}
export default About;


