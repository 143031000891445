import React from 'react';
import GaugeTable from '../components/GaugeTable'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../adapters/AppInsights';
import AppBanner from '../components/AppBanner';

class GaugeIndex extends React.Component {
    render() {
        return (
            <div class="container-fluid">
                <Header />
                <GaugeTable />
                <AppBanner />
                <Footer />
            </div>
        );
    }
}
export default withAITracking(reactPlugin, GaugeIndex);