import React from 'react';
import Header from './Header'
import Footer from './Footer'
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../adapters/AppInsights';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, Tooltip, XAxis, YAxis, ReferenceLine } from 'recharts';
import { getGaugeReadings } from '../adapters/DataHelper';

class GaugeHistoryGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            retrieved: false,
            gaugeData: null
        };
        this.retrieveGaugeHistory();
    }

    async retrieveGaugeHistory() {
        this.state = { submitted: true, retrieved: false, gaugeData: null };

        var gaugeId = (new URLSearchParams(window.location.search)).get("gaugeId");
        var currentDate = new Date();
        var endDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + (currentDate.getDate());
        var pastDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
        var startDate = pastDate.getFullYear() + '-' + (pastDate.getMonth() + 1) + '-' + pastDate.getDate();

        let response = await getGaugeReadings(gaugeId, startDate, endDate);
            this.setState({submitted: true, retrieved:true, gaugeData: response})
    }

    renderCustomAxis = ({ x, y, stroke, payload }) => {
        var labelDate = new Date(payload.value);
        var label = (labelDate.getMonth() + 1) + "/" + (labelDate.getDate()) + "/" + (labelDate.getFullYear());
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                    {label}
                </text>
            </g>
        );
    }

    customToolTip = ({ payload, label, active }) => {
        var display = (new Date(label).getMonth() + 1) + "/" + (new Date(label).getDate());

        if (active) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{display} : {payload[0].value}ft</p>
                </div>
                )
        }
    }

    renderLineChart() {
        var gaugeData = this.state.gaugeData.gaugeReadings;
        return (
            <ResponsiveContainer width="100%" height={500} >
                <LineChart data={gaugeData}>
                    <CartesianGrid strokeDasharray="5 5" />
                    <Line type="monotone" dataKey="stageMax" stroke="#9994d8" strokeWidth={2} dot={false} />
                    <XAxis dataKey="gaugeDate" tick={<this.renderCustomAxis />} />
                    <YAxis />
                    <Tooltip content={<this.customToolTip />} />
                </LineChart>
            </ResponsiveContainer>
        );
    }

    render() {
        let gaugeHistory = !this.state.retrieved
            ? <span>Loading ... </span>
            : this.renderLineChart();

        let gaugeName = !this.state.retrieved
            ? <span>Loading ... </span>
            : this.state.gaugeData.gauge.name;

        return (
            <div class="container-fluid">
                <Header />
                <div class="container col-12">
                    <h3>Mississippi River Gauge Readings</h3>
                    <p>Data collected over past 30 days.</p>
                    <dl class="row">
                        <dt class="col-sm-1">Gauge:</dt>
                        <dd class="col-sm-10">{gaugeName}</dd>
                    </dl>
                    {gaugeHistory}
                </div>
                <Footer />
            </div>
        );
    }
}
export default withAITracking(reactPlugin, GaugeHistoryGraph);
