
export async function getGaugeReadings(gaugeId, startDate, endDate)  
{
    var url = 'https://tbriverlevels.azurewebsites.net/api/Readings/' + gaugeId + '/' + startDate + '/' + endDate + '?code=M3KmVsylEylnLHnatscWnESpgRFu3wnJF-6DTwjLWti5AzFuTJuG_A==';
    return await fetch(url)
        .then(response => response.json())
        .then(response => {
            return response;
        });
}

export async function getGaugesMain() 
{
    var url = 'https://tbriverlevels.azurewebsites.net/api/Gauges?gauges=05587498,05587450,390019090412801,392229090542501,392709091025001,393810091145601,05501600,395556091245801&code=M3KmVsylEylnLHnatscWnESpgRFu3wnJF-6DTwjLWti5AzFuTJuG_A==';
    return await fetch(url)
        .then(response => response.json())
        .then(response => {
            return response;
        });
}

export async function getGaugeDataAll() 
{
    var url = 'https://tbriverlevels.azurewebsites.net/api/Gauges?code=M3KmVsylEylnLHnatscWnESpgRFu3wnJF-6DTwjLWti5AzFuTJuG_A==';
    return await fetch(url)
        .then(response => response.json())
        .then(response => {
            return response;
        });
}

export async function getGaugeList()
{
    var url = 'https://tbriverlevels.azurewebsites.net/api/GaugeList?code=M3KmVsylEylnLHnatscWnESpgRFu3wnJF-6DTwjLWti5AzFuTJuG_A==';
    return await fetch(url)
        .then(response => response.json())
        .then(response => {
            return response;
        });

}