import React from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom';

class About extends React.Component {
    render() {
        return (
            <div class="container-fluid">
                <Header />
                <h2>About GaugeStage.com</h2>
                <p>GaugeStage compiles river gauge levels from a selected group of gauges on the Mississippi River. This data is provided by the USGS and is considered public domain. </p>
                <p>This project started in the fall of 2020 due to a personal desire to monitor local river levels and was hosted as part of my personal web site, <a href="https://timbob.com" target="_blank" rel="noreferrer">timbob.com</a>.</p>
                <p>In 2021 the functionality was expanded to include additional gauges. Addional updates throughout 2021 and 2022 bring us to what is here, a modern ReactJS site.</p>
                <p>For more information, or to report bugs, please contact <a href="mailto:gaugestage@timbob.com">gaugestage@timbob.com</a>.</p>
                <p></p>
                <p><Link class="nav-link" to={'/support'}>Support</Link></p>
                <p><Link class="nav-link" to={'/privacy'}>Privacy Policy</Link></p>

                <Footer />
            </div>
        );
    }
}
export default About;