import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import GaugeIndex from './pages/GaugeIndex';
import GaugeHistory from './pages/GaugeHistory';
import GaugeHistoryGraph from './components/GaugeHistoryGraph';
import Embedded from "./pages/Embedded";
import EmbeddedDCYC from "./pages/EmbeddedDCYC";
import About from "./pages/About"
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './index.css';
import Support from './pages/Support';
import Privacy from './pages/Privacy';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <React.StrictMode>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <Routes>
                    <Route exact path="/" element={<GaugeIndex />} />
                    <Route exact path="/history" element={<GaugeHistory />} />
                    <Route exact path="/graph" element={<GaugeHistoryGraph />} />
                    <Route exact path="/EmbeddedGauges" element={<Embedded />} />
                    <Route exact path="/EmbeddedDCYC" element={<EmbeddedDCYC />} />
                    <Route exact path="/About" element={<About />} />
                    <Route exact path="/support" element={<Support />} />
                    <Route exact path="/privacy" element={<Privacy />} />
                </Routes>
            </QueryParamProvider>
        </React.StrictMode>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
