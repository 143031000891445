import React from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'

class Privacy extends React.Component {
    render() {
        return (
            <div class="container-fluid">
                <Header />
                <div>
                    <h2>Privacy Policy</h2>

                    <p>
                        GaugeStage is committed to protecting your privacy. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of information when you use our website and application.
                    </p>

                    <h4>Information We Do Not Collect:</h4>
                    <p>
                        We do not collect or store any personal information, including but not limited to names, addresses, phone numbers, or email addresses.
                    </p>

                    <h4>Automatically Collected Information:</h4>
                    <p>
                        Our website and application may collect certain information automatically, such as your device type, browser type, and usage patterns. However, this information is not linked to any personally identifiable information.
                    </p>

                    <h4>Cookies and Similar Technologies:</h4>
                    <p>
                        We do not use cookies or similar tracking technologies for collecting user information.
                    </p>

                    <h4>Third-Party Links:</h4>
                    <p>
                        Our website and application may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites. Please review their privacy policies before providing any personal information.
                    </p>

                    <h4>Children's Privacy:</h4>
                    <p>
                        Our services are not directed at children under the age of 13. We do not knowingly collect personal information from children. If you believe that we may have inadvertently collected information from a child, please contact us, and we will promptly take steps to delete it.
                    </p>

                    <h4>Security:</h4>
                    <p>
                        We take reasonable measures to protect the information we do not collect. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.
                    </p>

                    <h4>Changes to Privacy Policy:</h4>
                    <p>
                        We reserve the right to update or modify this Privacy Policy at any time without prior notice. Please review this policy periodically for any changes.
                    </p>

                    <h4>Contact Us:</h4>
                    <p>
                        If you have any questions or concerns about our privacy practices, please contact us at <a href="mailto:gaugestage@timbob.com">gaugestage@timbob.com</a>.
                    </p>

                    <p>
                        By using our website and application, you agree to the terms of this Privacy Policy. Last updated February 2, 2024.
                    </p>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Privacy;