import React from 'react';

function AppBanner() {
    return (
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-4">
                    <p>Advanced Version available for Mobile Devices</p>
                    <p>
                        <a href="https://apps.apple.com/us/app/gaugestage/id6477322714" target="_blank">
                            <img src="AppStore.png" width="200" />
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}



export default AppBanner;