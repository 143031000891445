import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../adapters/AppInsights';
import { getGaugesMain } from '../adapters/DataHelper';

class GaugeTableEmbedded extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            retrieved: false,
            gaugeDataRaw: null
        };
        this.getGaugesInfo();
    }

    async getGaugesInfo() {
        let response = await getGaugesMain();
            this.setState({submitted: true, retrieved:true, gaugeDataRaw: response});
    }

    renderGaugeDisplay(gaugeData) {
        var tableRows = [];
        for (var i = 0; i < gaugeData.length; i++) {
            var observedDateTime = new Date(gaugeData[i].observedDateTime);
            var displayTime = observedDateTime.toLocaleDateString() + ' ' + observedDateTime.getHours() + ':' + ('0' + observedDateTime.getMinutes()).slice(-2);

            tableRows.push(
                <tr>
                    <td class="fw-bold"><Link to={'/graph?gaugeId=' + gaugeData[i].gauge.rowKey }>{gaugeData[i].gauge.name}</Link></td>
                    <td class="fw-bold">{gaugeData[i].observedStage}</td>
                    <td class="gaugeData d-none d-sm-table-cell">{this.getDirection(gaugeData[i].change2H)} {Math.round(gaugeData[i].change2H * 100) / 100}</td>
                    <td class="gaugeData d-none d-sm-table-cell">{this.getDirection(gaugeData[i].change24H)} {Math.round(gaugeData[i].change24H * 100) / 100}</td>
                    <td class="gaugeData">{displayTime}</td>
                </tr>
            )
        }
        return (<tbody>{tableRows}</tbody>);
    }

    getDirection(value) {
        var indicator = "";
        if (value > 0) {
            indicator = (<FontAwesomeIcon icon={faArrowUp} />);
        } else if (value < 0) {
            indicator = (<FontAwesomeIcon icon={faArrowDown} />);
        }
        return (<span>{indicator}</span>);
    }

    render() {
        let gaugeDisplay = !this.state.retrieved
            ? <tbody><tr><td colspan="3">LOADING</td></tr></tbody>
            : this.renderGaugeDisplay(this.state.gaugeDataRaw)

        return (
            <div class="container col-12">
                
                <h3>Mississippi River Gauge Readings</h3>
                <table class="table table-sm">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">Gauge</th>
                            <th scope="col">Stage</th>
                            <th class="d-none d-sm-table-cell" scope="col">2 Hours</th>
                            <th class="d-none d-sm-table-cell" scope="col">24 Hours</th>
                            <th scope="col">Observed Time</th>
                        </tr>
                    </thead>
                    {gaugeDisplay}
                </table>
            </div>
        );
    }
}

export default withAITracking(reactPlugin, GaugeTableEmbedded);