import React from 'react';
import GaugeTableEmbedded from '../components/GaugeTableEmbedded'
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from '../adapters/AppInsights';
import './EmbeddedDCYC.css'

class EmbeddedDCYC extends React.Component {

    render() {
        return (
            <div class="container-fluid embedded">
                <GaugeTableEmbedded />
            </div>
        );
    }
}
export default withAITracking(reactPlugin, EmbeddedDCYC);
