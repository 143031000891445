import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <header>
            <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-light border-bottom box-shadow mb-3">
                <div class="container-fluid">
                    <Link class="navbar-brand" to={'/'}>
                        <img src="/logo50.png" height="32" width="32" class="d-inline-block align-text-center" alt="GaugeStage" />
                    </Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <Link class="nav-link" to={'/'}>Home</Link>
                            </li>
                            <li class="nav-item">
                                <Link to={'/history'} class="nav-link">Gauge Detail</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" to={'/about'}>About</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;